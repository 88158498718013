import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Dots } from 'app/components/Common/Dots'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { Email, Phone, Pin } from 'app/components/Icons'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  email?: string
  image?: ImageProps
  phone?: string
  pretitle?: string
  title?: string
}

export const IntroContacts = memo(function IntroContacts({
  address,
  email,
  image,
  phone,
  pretitle,
  title,
}: Props) {
  return (
    <Container>
      <TextWrapper>
        {pretitle ? (
          <FadeInUp>
            <Line />
            <Pretitle>{pretitle}</Pretitle>
          </FadeInUp>
        ) : (
          <Line />
        )}
        {title ? <Title text={title} /> : null}
        <Contacts>
          {address ? (
            <FadeInUp>
              <Contact>
                <Icon>
                  <Pin />
                </Icon>
                {address}
              </Contact>
            </FadeInUp>
          ) : null}
          {phone ? (
            <FadeInUp delay={0.15}>
              <Contact>
                <Icon>
                  <Phone />
                </Icon>
                <a
                  aria-label="link"
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                  rel="noopener"
                >
                  {phone}
                </a>
              </Contact>
            </FadeInUp>
          ) : null}
          {email ? (
            <FadeInUp delay={0.3}>
              <Contact>
                <Icon>
                  <Email />
                </Icon>
                <a
                  aria-label="link"
                  href={`mailto:${email}`}
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })
                  }}
                  rel="noopener"
                >
                  {email}
                </a>
              </Contact>
            </FadeInUp>
          ) : null}
        </Contacts>
        <StyledDots width={7} height={6} />
      </TextWrapper>

      {image ? (
        <ImageWrapper>
          <StyledImage {...image} />
        </ImageWrapper>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  padding: 10vw;

  @media (min-width: 992px) {
    margin-bottom: -8vw;
    padding-bottom: 0;
  }

  @media (max-width: 991px) {
    display: block;
    padding: 70px 30px;
    margin-top: 70px;
  }
`

const TextWrapper = styled.div`
  position: relative;
  width: 52%;
  max-width: 100%;
  padding-right: 10vw;

  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
  }
`

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  z-index: 1;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled(AnimatedTitle)`
  position: relative;
  margin-top: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.133;
  z-index: 1;

  @media (max-width: 1439px) {
    font-size: 28px;
  }
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const Contacts = styled.div`
  position: relative;
  margin-top: 4.16vw;
  z-index: 1;

  @media (max-width: 1439px) {
    margin-top: 20px;
  }
`

const Contact = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.04vw;
  line-height: 1.73;

  padding-bottom: 1.38vw;

  @media (max-width: 1439px) {
    font-size: 15px;
    line-height: 26px;
  }

  @media (max-width: 1439px) {
    padding-bottom: 10px;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.77vw;
  height: 2.77vw;
  min-width: 2.5rem;
  min-height: 2.5rem;
  margin-right: 0.9vw;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};

  @media (max-width: 1439px) {
    margin-right: 13px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.variants.neutralLight1};
  }
`

const StyledDots = styled(Dots)`
  position: absolute;
  top: 50%;
  right: 4.16vw;
  transform: translateY(-50%);
`

const ImageWrapper = styled.div`
  position: relative;
  width: 48%;
  z-index: 3;

  @media (max-width: 1439px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const StyledImage = styled(Image)`
  display: block;
  border-radius: 0.69vw;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1439px) {
    border-radius: 6px;
  }
`
