import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  ContactsFormSenderBackend,
  FormData,
} from 'app/utils/ContactsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  description?: string
  languageCode: string
  onContactsFormSendToBackend: ContactsFormSenderBackend
  title?: string
}

export const ContactsForm = memo(function ContactsForm({
  description,
  languageCode,
  onContactsFormSendToBackend,
  title,
}: Props) {
  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onContactsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Contacts Form',
          })
      }
    },
    [isSendingForm, onContactsFormSendToBackend],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}

        <FadeInUp>
          <Form
            onSubmit={isSendingForm ? undefined : onSubmit}
            noValidate
            autoComplete={'off'}
          >
            <Spinner
              className={isSendingForm ? undefined : 'hidden'}
              variant="form"
            />

            <Box row wrap space="between">
              <Field>
                <Label>{`${useVocabularyData('name', languageCode)}*`}</Label>
                <Input
                  className="dark"
                  error={formState.errors.name !== undefined}
                  isDirty={formState.dirtyFields.name}
                  type="text"
                  required={true}
                  {...register('name', {
                    required: true,
                    validate: (name) => name.length > 2,
                  })}
                />
              </Field>
              <Field>
                <Label>{`${useVocabularyData(
                  'lastname',
                  languageCode,
                )}*`}</Label>
                <Input
                  className="dark"
                  error={formState.errors.lastname !== undefined}
                  isDirty={formState.dirtyFields.lastname}
                  type="text"
                  required={true}
                  {...register('lastname', {
                    required: true,
                    validate: (lastname) => lastname.length > 2,
                  })}
                />
              </Field>
              <Field>
                <Label>{`${useVocabularyData('email', languageCode)}*`}</Label>
                <Input
                  className="dark"
                  error={formState.errors.email !== undefined}
                  isDirty={formState.dirtyFields.email}
                  type="email"
                  required={true}
                  {...register('email', {
                    required: true,
                    validate: (email) => EMAIL_REGEX.test(email),
                  })}
                />
              </Field>
              <Field>
                <Label>{`${useVocabularyData('phone', languageCode)}*`}</Label>
                <Input
                  className="dark"
                  error={formState.errors.phone !== undefined}
                  isDirty={formState.dirtyFields.phone}
                  type="text"
                  {...register('phone', {
                    required: true,
                    validate: (phone) => phone.length > 2,
                  })}
                />
              </Field>
              <Field className="large-field">
                <Label>{useVocabularyData('message', languageCode)}</Label>
                <Textarea className="dark" {...register('message')} />
              </Field>
              <Checkboxes>
                <Checkbox
                  className="dark"
                  error={formState.errors.privacy_policy !== undefined}
                  label={useVocabularyData('form-policy-text', languageCode)}
                  required={true}
                  {...register('privacy_policy', {
                    required: true,
                  })}
                />
              </Checkboxes>
              <Input
                name="submit"
                type="submit"
                value={useVocabularyData('send-request', languageCode)}
                variant="submit"
              />
            </Box>

            {displaySendErrorFeedback ? (
              <FormMessages
                languageCode={languageCode}
                text={useVocabularyData('form-error-message', languageCode)}
                title={useVocabularyData(
                  'form-error-message-title',
                  languageCode,
                )}
                type="error"
              />
            ) : null}

            {displaySendConfirmFeedback ? (
              <FormMessages
                languageCode={languageCode}
                text={useVocabularyData(
                  'form-confirmation-message',
                  languageCode,
                )}
                title={useVocabularyData(
                  'form-confirmation-message-title',
                  languageCode,
                )}
              />
            ) : null}
          </Form>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 8vw 10vw 10vw;
  background-color: ${({ theme }) => theme.colors.variants.neutralDark2};

  @media (max-width: 991px) {
    padding: 60px 30px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
`

const Title = styled.h2`
  position: relative;
  display: block;
  padding-left: 3.5vw;
  margin-bottom: 65px;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.77vw;
  line-height: 1.15;
  background: url('/star-contacts.svg') center left no-repeat;
  background-size: 2.11vw auto;

  @media (max-width: 1439px) {
    font-size: 28px;
    background-size: 20px auto;
    padding-left: 30px;
    margin-bottom: 10px;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
`

const Form = styled.form`
  margin-top: 3.75rem;
  position: relative;

  @media (max-width: 1439px) {
    margin-top: 10px;
  }
`

const Box = styled(FlexBox)``

const Field = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 2.25rem;
  text-align: left;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
  }

  &.small-field {
    width: calc(25% - 2.8125rem);

    @media (max-width: 768px) {
      width: calc(50% - 1.875rem);
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  &.large-field {
    width: 100%;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.625rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`

const Checkboxes = styled.div`
  margin-top: 2.25rem;
`
